define(['app'], (app) => {

  const videoPlayer = () => {
    const component = {};

    const _selectors = {
      video: '.videoPlayer',
      transcriptButton: '.videoPlayer_transcriptButton',
      transcriptCheck: '[data-transcript]'
    };

    const _init = (element) => {
      component.element = element;
      component.video = element.querySelector(_selectors.video);
      component.transcriptButton = element.querySelector(_selectors.transcriptButton);
      component.transcriptCheck = element.querySelector(_selectors.transcriptCheck);
      component.video.addEventListener('play', component.videoPlay);
      component.video.addEventListener('pause', component.videoPause);

      if(component.transcriptCheck.innerHTML === ''){
        component.transcriptButton.remove();
      }

      return component;
    };


    const _videoPlay = () => {
      app.publish('tracking/record', 'productImageCarousel', 'video', 'play');
      app.publish('columbo/track', 'productImageCarousel', 'video', 'play');
    };

    const _videoPause = () => {
      app.publish('tracking/record', 'productImageCarousel', 'video', 'pause');
      app.publish('columbo/track', 'productImageCarousel', 'video', 'pause');
    };

    component.init = _init;
    component.videoPlay = _videoPlay;
    component.videoPause = _videoPause;


    return component;
  };

  return videoPlayer;
});
